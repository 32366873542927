@import "src/styles/utils";

.tooltipContainer {
  @include flex-centered(true, false);
  justify-content: flex-start;
  position: absolute;
  font-size: 14px;
  line-height: 20px;
  background: linear-gradient(180deg, #491239 0%, #141930 92.23%);
  border-radius: 8px;
  height: 130px;
  top: -180px;
  width: 110px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 0.7rem 1.2rem 2rem 1.2rem;
  margin: 0;
  z-index: 50;
  transition: all 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    top: calc(100% - 8px);
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 20px solid #141930;
  }

  h3 {
    margin: 0;
  }

  @include is-tablet-portrait {
    top: -185px;
  }
}