@import "src/styles/utils";

.settings {
  @include flex-centered(true, true);
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: 1rem auto;

  @include is-tablet-portrait {
    max-width: 540px;
  }
}

.actionBoard {
  @include flex-centered(false, false);
  position: relative;
  height: 4.5rem;
  width: 100%;
  max-width: 360px;
  z-index: 10;

  @include is-tablet-portrait {
    max-width: 540px;
  }

  button {
    width: 118px;

    @include is-tablet-portrait {
      width: 145px;
      height: 60px;
    }

    @include is-desktop-large {
      width: 165px;
    }
  }
}

.bottomBlock {
  @include flex-centered(true, false);
  position: relative;
  width: 100%;
  z-index: 5;

  .playButton {
    width: 100%;
    height: 50px;
    max-width: 365px;
    margin-top: 5px;
    font-size: 22px;
    line-height: 20px;
    text-shadow: $button-text-shadow;
    background-image: url("../../assets/svg/button-big.svg");
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.2s ease-in-out;

    .playButtonText {
      position: relative;
      top: -3px;
    }

    @include is-tablet-portrait {
      height: 58px;
      max-width: 440px;
    }

    @include is-desktop-large {
      line-height: 60px;
      height: 67px;
      max-width: 500px;
    }
  }

  .backToStartButton {
    position: relative;
    top: 0.5rem;
    letter-spacing: -0.05rem;
    text-shadow: $button-text-shadow;
    text-decoration: underline;
  }
}
