@import "src/styles/utils";

.wrapper {
  @include flex-centered(false, true);
  @include button-text;
  position: relative;
  pointer-events: all;

  width: 100%;
  height: 45px;

  padding: 0;
  margin: 5px;

  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }

  &.disabled {
    filter: grayscale(20%) brightness(0.6);
    pointer-events: none;
  }

  @include is-tablet-portrait {
    height: 50px;
  }
}

.childrenWrapper {
  @include flex-centered(true);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.primary {
  @include button-text;
  background-image: url("../../../assets/svg/button-small-mobile.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 110px;
  height: 58px;

  @include is-tablet-portrait {
    background-image: url("../../../assets/svg/button-small.svg");
  }
}

.secondary {
  background: transparent;
  border: none;
  margin-top: 5px;
}

.tertiary {
  background: $tertiary-button-background;
  color: $cell-text-color;
  height: 30px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 400;
}

.menuButton {
  width: 26px;
  height: 26px;
  background: transparent;
  border: none;
  color: $light-text-color;

  @include is-tablet-portrait {
    width: 34px;
    height: 34px;
  }
}

.disabled {
  pointer-events: none;
  box-shadow: none;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &::before {
        background-color: transparent;
      }
    }
  }
}

