
// Colors
$background-color: var(--background-color);
$board-background: var(--board-background);
$background-color-transparent: var(--background-color-transparent);
$board-background-transparent: var(--board-background-transparent);
$cell-color: var(--blue);
$cell-text-color: var(--light-blue);
$cell-text-color-active: var(--blue);
$cell-color-active: var(--light-blue);
$gradient-blue: var(--gradient-blue);
$color-white: #fff;
$color-white-transparent: rgba(255, 255, 255, 0.7);
$color-white-more-transparent: rgba(255, 255, 255, 0.3);
$color-grey: var(--grey-color);
$color-davys-grey: var(--davys-grey-color);
$color-black: black;
$color-gold: #EEDEAD;
$color-gold-transparent: rgba(238, 222, 173, 0.7);

$light-text-color: var(--light-text-color);
$grid-text-color: var(--grid-text-color);
$modal-overlay: rgba(#20294F99, 0.6);
$modal-background: var(--modal-background);

// Buttons
$button-border-radius: var(--button-border-radius);
$button-background: var(--button-background);
$button-text-shadow: var(--button-text-shadow);
$tertiary-button-background: var(--blue);
$menu-button-background: rgba(#000000, 0.3);

// Topbar
$topbar-background: var(--topbar-background);
$topbar-background-desktop: var(--topbar-background-desktop);
$topbar-height: var(--topbar-height-mobile-info);
$topbar-height-mobile: var(--topbar-height-mobile-game);
$topbar-height-tablet: var(--topbar-height-tablet-start);
$topbar-height-small-destop: var(--topbar-height-desktop-small);
$topbar-height-desktop: var(--topbar-height-desktop);
$topbar-top: var(--topbar-inactive-top);
$balance-background: var(--balance-background);

// Game info
$info-background: var(--game-info-background);
$prizes-background: var(--prizes-background);

// Footer
$footer-background: var(--footer-background);
$footer-height: var(--footer-text-color);

// Fonts
$root-font-family: var(--root-font-family);
$balance-font-family: var(--balance-font-family);
$root-font-size: var(--root-font-size);
$root-font-size-tablet: var(--root-font-size-tablet);
$root-font-size-desktop: var(--root-font-size-desktop);
$root-font-weight: var(--root-font-weight);

// Breakpoints
$screen-mobile-portrait: 480px;
$screen-tablet-portrait: 768px;
$screen-laptop-height: 900px;
$screen-desktop: 1200px;
$screen-desktop-large: 1440px;

$page-top-padding-mobile: 5rem;
$page-top-padding-tablet: 6rem;

// Variable that dynamically senses viewport height
$app-height: var(--inner-height);
