@import "src/styles/utils";

.bookmark {
  @include flex-centered(false, true);
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  color: $light-text-color;
  cursor: pointer;
  position: absolute;
  top: 14dvh;
  left: 100px;
  width: 65px;
  height: 25px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  z-index: 80;

  .bookmarkText {
    @include h3;
    line-height: 12px;
    font-weight: bold;
  }

  .bookmarkIcon {
    width: 22px;
    height: 22px;
  }

  &.bookmarkOpen {
    z-index: 120;
  }

  @include is-small-mobile {
    top: 85px;
  }
}

.container {
  @include flex-centered(true, true);
  justify-content: start;
  padding-top: 1em;
  position: absolute;
  top: 10dvh;
  left: 0;
  width: 270px;
  min-height: 400px;
  color: $light-text-color;
  background: $prizes-background;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  text-align: center;
  z-index: 120;

  .headerBox {
    @include flex-centered(false);
    justify-content: space-between;
    align-items: flex-end;
    width: 245px;
    margin: 5px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    color: $light-text-color;

    h1 {
      font-size: 24px;
      line-height: 24px;
      font-weight: bold;
      margin: 0;
    }

    h3 {
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
  }

  @include is-small-mobile {
    top: 11dvh;
  }
}

.breakLine {
  width: 80%;
  margin: 0;
  border-top: 1px solid $light-text-color;
}

.containerContent {
  width: 90%;
  padding: 15px 0;
}

@include is-tablet-portrait {
  .bookmark {
    top: calc($topbar-height + 20px);
    height: 35px;
    width: 70px;
  }

  .container {
    top: 14dvh;
  }
}

@include is-desktop {
  .bookmark {
    display: none;
  }

  .container {
    position: relative;
    transform: none !important;
    box-shadow: none;
    opacity: 1;
    width: 320px;
    min-height: 440px;
    background: $info-background;
    border-radius: 30px 0 0 30px;
  }

  @media screen and (max-height: 700px) {
    .container {
      top: 20dvh;
    }
  }
}

@include is-desktop-large {
  .container {
    top: 20dvh;
  }
}
