@import '../../../../styles/utils/index';

.box {
  @include flex-centered;
  justify-content: flex-start;
  margin-top: 5px;
  padding: 4px 8px;

  background: rgba(black, 0.32);
  min-width: 55px;
  min-height: 24px;
  box-shadow: 0px 0px 7.5px 0px rgba(246, 243, 202, 0.32);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  @include is-tablet-portrait {
    margin-top: 2px;
  }

  @include is-desktop {
    min-width: 100px;
    min-height: 35px;
    border-radius: 8px;
  }
}

.coinIcon {
  width: 11px;
  height: auto;
  margin-right: 4px;

  @include is-desktop {
    width: 17px;
    margin-right: 8px;
  }
}

.balance {
  margin: 0;

  color: var(--color-white);
  font-weight: bold;
  font-family: $balance-font-family;
  font-size: 12px;
  line-height: 13px;

  @include is-desktop {
    font-size: 14px;
  }
}

.hideBalance {
  display: none;
}
