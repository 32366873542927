@import "src/styles/utils";

.menu {
  position: relative;
  left: 8px;
  @include flex-centered(true, false);
  justify-content: flex-start;
  width: 45px;
  border-radius: 10px;

  @include is-tablet-portrait {
    width: 55px;
  }
}

.menuButton {
  @include flex-centered(true, true);
  padding: 5px;
  margin: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: $menu-button-background;
  border: none;
  border-radius: 8px;

  &.mainButton {
    @include is-tablet-portrait{
      margin: 0;
    }
  }

  &.hideButton {
    display: none;

    & + .third {
      top: 99px !important;

      @include is-tablet-portrait {
        top: 136px !important;
      }
    }

    ~ .closeGameButton,
    &.closeGameButton {
      top: 143px !important;

      @include is-tablet-portrait {
        top: 199px !important;
      }
    }
  }

  @include is-tablet-portrait {
    width: 40px;
    height: 40px;
  }

  @include is-desktop {
    left: 30px;
  }
}

.menuIcon {
  opacity: 0;
  height: 15px;
  width: 15px;
  animation: bounce-in 0.3s ease forwards;

  &.isMenuClosedIcon {
    animation: fade-in 0.2s 0.3s ease forwards;
  }

    @include is-tablet-portrait {
      height: 21px;
      width: 21px;
    }
}

@keyframes bounce-in {
  0% {
    scale: 0;
    opacity: 0;
  }

  100% {
    scale: 1;
    opacity: 1;
  }
}
