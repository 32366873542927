.turnDeviceScreen {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  height: 100vh;
  background: #1b2c4d;
  text-align: center;
  z-index: 1000;
}

.turnDeviceText {
  color: var(--color-white) !important;
  letter-spacing: .1em;
  font-weight: var(--root-font-weight);
  font-size: var(--root-font-size);
  font-family: var(--root-font-family);
  text-transform: uppercase;
}

.turnDeviceLogo {
  position: relative;
  width: 108px;
  height: 25px;
  top: -5%;
}
