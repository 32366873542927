@import "src/styles/utils";

.skipDrawingContainer {
  width: 100%;
  @include flex-centered(false);
  justify-content: space-between;

  .skipDrawingButton {
    width: 50%;
    text-decoration: underline;
    font-weight: bolder;
    font-size: 16px;
  }

  .toggleContainer {
    width: 30%;
    @include flex-centered(false, true);
    justify-content: space-evenly;
  }
}
