@import "src/styles/utils";

.tableBox {
  table {
    width: 100%;
    border-collapse: collapse;

    th {
      font-size: 9px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 9px;
      color: $color-white-transparent;

      .headerImage {
        width: 20px;
        height: 20px;
        margin: 0 0 0 5px;
      }

      &:first-child {
        text-align: left;
        max-width: 35px;
      }

      &:last-child {
        @include flex-centered(false, false);
        justify-content: flex-end;
        text-align: right;
        max-width: 120px;
      }
    }

    td {
      font-size: 12px;
      min-width: 35px;
      text-transform: uppercase;
      font-weight: bold;
      border-top: 1px solid $color-davys-grey;
      border-bottom: 1px solid $color-davys-grey;
      padding: 3px;
      text-align: right;
      color: $light-text-color;

      &:first-child {
        text-align: left;
        max-width: 35px;
      }
    }

    .noData {
      text-align: center !important;
      padding: 10px;
      font-size: 14px;
      color: $light-text-color;
    }
  }
}
