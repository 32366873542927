@import "src/styles/utils";

.multiplierBox {
  margin: 0.5rem 1rem;
  color: $color-white-more-transparent;
  text-align: right;

  .multiplierHeader {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    margin: 0;
  }

  .progressText {
    line-height: 18px;
    font-weight: 500;
    font-size: 18px;
    text-align: right;
    margin: 0;
  }

  @include is-tablet-portrait {
    .multiplierHeader {
      font-size: 12px;
      line-height: 18px;
    }

    .progressText {
      line-height: 20px;
      font-size: 20px;
    }
  }
}

.tableBox {
  border: 1px solid $color-white-more-transparent;
  border-right: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 110px;
  padding: 10px;
  color: $color-white-more-transparent;

  @media screen and (min-width: 1421px) {
    border-radius: 10px;
    border: 1px solid $color-white-more-transparent;
  }
}

.table {
  width: 100%;

  thead tr th:last-child {
    text-align: right;
  }
}

.progressRow {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 14px;

  .hitsData {
    text-align: center;
  }

  .prizesData {
    text-align: right;
  }

  &.activeRow {
    .hitsData,
    .prizesData {
      color: $light-text-color;
    }
  }

  &.goldRow {
    .prizesData {
      color: $color-gold-transparent;
    }
  }

  &.activeGoldRow {
    .hitsData {
      color: $light-text-color;
    }

    .prizesData {
      color: $color-gold;
    }
  }

  @include is-tablet-portrait {
    font-size: 13px;
    line-height: 15px;
  }
}

