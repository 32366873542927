@import "src/styles/utils";

.infoPage {
  @include flex-centered(true, false);
  max-width: 340px;
  width: 100%;
  margin-top: calc(var(--topbar-height-mobile-start) + 0.5rem);

  p {
    @include paragraph;
    font-size: 14px;
    line-height: 1.5rem;

    @include is-tablet-portrait {
      font-size: 16px;
      line-height: 1.7rem;
    }

    @include is-desktop {
      font-size: 18px;
      line-height: 1.7rem;
    }
  }

  .bottomBlock {
    @include flex-centered(true, true);
    width: 100%;
    max-width: 340px;
    padding: 0 1rem;

    .statsButton {
      text-decoration: underline;
      text-shadow: $button-text-shadow;
    }

    .startButton {
      background-image: url("../../assets/svg/button-small.svg");
      width: 150px;
      height: 60px;
      max-width: 270px;
      text-shadow: $button-text-shadow;
      font-size: 22px;
    }
  }

  @include is-tablet-portrait {
    max-width: 500px;
    margin-top: var(--topbar-height-tablet-start);
  }

  @include is-desktop {
    max-width: 700px;
    min-height: 40dvh;
    z-index: 30;
    background: $info-background;
    padding: 2rem;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: var(--topbar-height-mobile-start);

    .tutorialBox {
      max-width: 560px;
    }
  }
}
