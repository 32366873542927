@import "src/styles/utils";

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin: 15px 0;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background: linear-gradient(180deg, rgba(216, 167, 139, 0.8) 0%, rgba(196, 50, 85, 0.8) 100%);
  box-shadow: 0 6px 8px 3px rgba(0, 0, 0, 0.1) inset;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  border-radius: 50%;
  background-color: $light-text-color;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background: linear-gradient(180deg, rgba(216, 167, 139, 1) 0%, rgba(196, 50, 85, 1) 100%);
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}
