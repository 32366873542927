@import "src/styles/utils";

.prizesPage {
  @include flex-centered(true, false);
  color: $light-text-color;
  max-width: 340px;
  width: 100%;
  margin-top: calc(var(--topbar-height-mobile-start) + 1rem);

  p {
    @include paragraph;
  }

  .bottomBlock {
    @include flex-centered(true, true);
    width: 100%;
    max-width: 340px;
    padding: 0 1rem;

    .statsButton {
      text-decoration: underline;
      text-shadow: $button-text-shadow;
    }

    .startButton {
      background-image: url("../../assets/svg/button-small.svg");
      width: 150px;
      height: 60px;
      max-width: 270px;
      text-shadow: $button-text-shadow;
      font-size: 22px;
    }
  }

  .prizesBox {
    width: 100%;
    max-width: 350px;

    th {
      &:last-child {
        max-width: 220px;
      }
    }
  }

  @include is-tablet-portrait {
    max-width: 500px;
    margin-top: calc(var(--topbar-height-tablet-start) + 0.5rem);

    .prizesBox {
      width: 100%;
      max-width: 390px;

      th {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 12px;
        padding: 0.5rem 0;
        color: $color-white-transparent;

        .headerImage {
          width: 20px;
          height: 20px;
          margin: 0 0 0 5px;
        }

        &:last-child {
          max-width: 250px;
        }
      }

      td {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  @include is-desktop {
    max-width: 700px;
    min-height: 40dvh;
    z-index: 30;
    background: $info-background;
    padding: 2rem;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: var(--topbar-height-mobile-start);
  }
}
