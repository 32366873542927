@import "../../../../styles/utils/index";

.multiplierBox {
  @include flex-centered(true, true);
  position: relative;
  z-index: 40;
}

.multiplierButton {
  @include flex-centered(true, true);
  margin: 2px;
  padding: 0;

  .multiplierButtonContent {
    position: relative;
    left: -0.2rem;
    @include flex-centered(false);
    justify-content: flex-start;

    @include is-tablet-portrait {
      left: -0.3rem;
      top: -0.2rem;
    }

    @include is-desktop-large {
      left: -0.4rem;
      top: -0.1rem;
    }
  }

  .textBox {
    @include flex-centered(true);
    text-align: left;
    margin: 5px 10px 0 5px;
  }

  .multiplierButtonText {
    letter-spacing: -0.05rem;
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    padding: 0;
    max-width: 65px;
  }

  .multiplierButtonPrice {
    font-size: 9px;
    margin: 5px 0 0 0;
    line-height: 10px;
    padding: 0;
    transition: all 0.2s;
  }

  &:hover {
    .multiplierButtonPrice {
      font-size: 12px;
    }
  }

  input[type=checkbox] {
    display: none;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    width: 12px;
    height: 12px;
    border: 1px solid #DFAD9E;
    border-radius: 3px;
    background: rgba(105, 11, 11, 0.5);

    @include is-tablet-portrait {
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
  }

  @include is-tablet-portrait {
    width: 142px;
  }

  @include is-desktop-large {
    width: 165px;
  }
}

.wheel {
  position: absolute;
  width: 30px;
  top: 0;
  z-index: 30;

  @include is-tablet-portrait {
    width: 33px;
  }

  @include is-desktop-large {
    width: 37px;
  }
}
