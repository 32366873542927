@import "../../../../styles/utils/index";

.activePriceButton {
  margin: 2px;
  text-shadow: $button-text-shadow;
  background-image: url("../../../../assets/svg/button-small-mobile.svg");
  background-repeat: no-repeat;
  background-size: cover;

  @include is-tablet-portrait {
    width: 140px;
    background-image: url("../../../../assets/svg/button-small.svg");
  }
}

.priceList {
  @include flex-centered(true, true);
  position: absolute;
  bottom: 4px;
  left: -6px;
  height: fit-content;
  border-radius: 18px;
  z-index: 35;

  .priceButton {
    text-shadow: $button-text-shadow;

    .stake {
      position: relative;
      top: -4px;
    }

    &.inactive {
      @media (hover: hover) and (pointer: fine) {
        &:hover {

        }
      }
    }

    @include is-tablet-portrait {
      width: 145px;
      height: 60px;
      left: -6px;
      bottom: -6px;
      margin: 4px 3px 4px 7px;
    }

    @include is-desktop-large {
      width: 178px;
      height: 60px;
      left: -8px;
      bottom: -4px;
      margin: 6px 0 6px 10px;
    }
  }

  @include is-tablet-portrait {
    left: -2px;
    bottom: 7px;
  }

  @include is-desktop-large {
    bottom: 8px;
  }
}

.stake {
  position: relative;
  top: -4px;
  font-size: 18px;
  margin: 0;
  text-shadow: $button-text-shadow;
}

.topPrizeLabel {
  position: relative;
  top: -4px;
  font-size: 8px;
  margin: 0;
  text-shadow: none;
  line-height: 9px;
  transition: all 0.2s;
}

.topPrizeValue {
  position: relative;
  top: -2px;
  font-size: 8px;
  line-height: 10px;
  margin: 0;
  text-shadow: none;
}
