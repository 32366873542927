@import "src/styles/utils";

.App {
  width: 100%;
  margin: 0;
  top: 0;
  background-color: var(--background-color);
  overflow: hidden;

  &.selectingGoldNumber {
    box-shadow: 0 0 9px #df9e3e inset;
    transition: box-shadow 0.5s;

    @include is-tablet-portrait {
      box-shadow: none;
    }
  }
}

#root, .App {
  position: relative;
  min-height: 100dvh;

  @include is-tablet-portrait {
    height: 100vh;
  }
}

body {
  margin: 0;
  font-family: var(--root-font-family);
  font-weight: var(--root-font-weight);
  font-size: var(--root-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

h1 {
  @include h1;
}
