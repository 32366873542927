@import "../../../../styles/utils/index";

.ball {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: radial-gradient(circle at 50% 50%, #FFE600, #FF8A00);

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    right: 8px;
    width: 9px;
    height: 18px;
    border-radius: 50%;
    box-shadow: -4px 0 0 rgba(255, 255, 255, 0.45);
    transform: rotate(-218deg);
  }

  &::before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%;
    background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 58%);
    filter: blur(5px);
    z-index: 2;
  }
}



