@import "src/styles/utils";

.keno {
  @include flex-centered(false, false);
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}

.mainBoard {
  @include flex-centered(true);
  justify-content: flex-end;
  height: 100%;
  max-width: 100%;

  &.info,
  &.stats,
  &.multiplier {
    display: none;
  }
}

.numbers {
  width: 300px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.kenoCounter {
  display: none;
  transition: all 0.2s ease;
  background-color: $color-black;
  color: $light-text-color;
  width: 235px;
  height: 20px;
  border-radius: 30px 30px 0 0;
  opacity: 0;
  z-index: 10;

  &.kenoCounterActive {
    opacity: 1;
  }

  &.isHidden {
    display: none;
  }

  h3 {
    @include h3;
    margin: 3px;
  }

  @include is-tablet-portrait {
    @include flex-centered(true);
    width: 340px;
    height: 30px;
  }

  @include is-desktop-large {
    width: 400px;
    height: 35px;
    position: relative;
    top: calc(var(--topbar-height-desktop) - 150px);
    align-items: center;
  }
}

