@import "../../../../styles/utils/index";

.randomizerBox {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 360px;

  @include is-tablet-portrait {
    max-width: 440px;
  }

  @media screen and (min-width: 1200px) {
    justify-content: space-between;
    max-width: 275px;
  }

  .randomize {
    @include flex-centered(true, true);
  }
}

.randomizeMenuBox {
  position: absolute;
  min-height: 0;
  max-height: 0;
  width: 175px;
  background-color: transparent;
  box-shadow: 0 4px 4px 0 #00000026;
  border-radius: 5px;
  top: 25px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.9);

  &::before {
    content: "";
    position: absolute;
    border-radius: 5px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(#FFFFFF, 0.3) 0%, rgba(#3FACD2, 1) 100%);
  }

  &.isOpen {
    @include flex-centered(true, true);
    visibility: visible;
    opacity: 1;
    max-height: 400px;
    transform: scale(1);
    background-color: $gradient-blue;
    animation: bounceOut 0.6s ease-out forwards;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .randomizerTitle {
    margin-top: 12px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 9px;
    color: $background-color;
  }

  .randomizeMenu {
    @include flex-centered(false, true);
    margin-bottom: 5px;
    flex-wrap: wrap;

    .randomizeButton {
      @include h1;
      color: $cell-text-color;
      height: 2.2rem;
      width: 2.2rem;
      margin: 3px;

      @media screen and (min-width: 1200px) {
        @include h3;
        height: 1.5rem;
        width: 1.5rem;
        margin: 2px;
      }
    }
  }

  @include is-tablet-portrait {
    width: 215px;
    top: 35px;
  }

  @media screen and (min-width: 1200px) {
    width: 130px;
  }
}

.randomizerToggle {
  @include h3;
  display: block;
  position: relative;
  cursor: pointer;
  margin: 2px;
  width: 175px;
  height: 30px;
  transition: background-color 0.2s ease-in;
  z-index: 15;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5 !important;
  }

  &.active {
    color: $cell-text-color-active;
    background-color: $cell-color-active;
    box-shadow: 0 4px 4px 0 #0000001A;
  }

  @include is-tablet-portrait {
    height: 40px;
    width: 215px;
  }

  @media screen and (min-width: 1200px) {
    width: 130px;
    border: 1px solid rgba(162, 231, 255, 1);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(0.9) translateY(-20px);
    opacity: 0;
  }
  50% {
    transform: scale(1.05) translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}
