@import "src/styles/utils";

.board {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 360px;
  width: 100%;
  padding-top: calc(var(--topbar-height-mobile-start) + 0.5rem);
  z-index: 30;
  transition: all 0.2s;

  &.game,
  &.endGame {
    padding-top: calc(var(--topbar-height-mobile-game) + 0.5rem);

    @include is-tablet-portrait() {
      padding: 1rem;
    }
  }

  &.game {
    justify-content: space-between;
  }

  &.disabled .row,
  .submitCard:disabled {
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }

  .row {
    display: flex;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    justify-content: center;

    .cell {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $cell-color;
      color: $cell-text-color;
      font-weight: 400;
      border-radius: 5px;
      margin: 2px;
      width: 2rem;
      height: 2rem;
      line-height: 30px;
      text-align: center;
      border: none;
      transition: all 0.2s ease-in;
      overflow: hidden;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          cursor: pointer;
          background-color: $cell-color-active;
          color: $cell-text-color-active;
        }
      }

      &.picked {
        color: $cell-text-color-active;
        background-color: $cell-color-active;

        &::after {
          content: "";
          position: absolute;
          border-left: solid 2px rgba(white, 0.5);
          width: 2px;
          height: 9px;
          border-top-left-radius: 5px;
          left: 2px;
          top: 1px;
        }
      }

      &.gold {
        background: linear-gradient(132.37deg, #FFDAA2 10.14%, #DF9E3D 45.85%, #EEC68A 60.05%, #F9BB5E 81.99%, #9B6413 96.18%);
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      @include is-tablet-portrait() {
        width: 2.5rem;
        height: 2.5rem;
        margin: 2px;
        font-size: 19px;

        @media screen and (max-height: 700px) {
          width: 2.5rem;
          height: 2rem;
          margin: 2px;
          font-size: 17px;
        }
      }

      @include is-desktop-large() {
        width: 3rem;
        height: 3rem;
        margin: 3px;
        font-size: 21px;
      }
    }

    @include is-tablet-portrait() {
      max-width: 510px;
    }

    @include is-desktop-large() {
      max-width: 540px;
    }
  }

  @include is-tablet-portrait() {
    padding: 1rem;
    background-color: $board-background;
    border-radius: 30px 30px 0 0;
    max-width: 440px;
    min-height: 540px;

    &.selectingGoldNumber {
      border: 1px solid $color-gold;
      border-bottom: none;
    }

    @media screen and (max-height: 700px) {
      min-height: 420px;
    }

    &.endGame {
      background-color: $background-color;
      padding: 1rem 3rem;
      min-height: 55%;
    }
  }

  @include is-desktop-large() {
    padding: 1.2rem 1.2rem 0.5rem 1.2rem;
    max-width: 540px;
  }
}
