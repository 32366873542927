@mixin flex-centered($direction-column: false, $justify: false) {
  display: flex;
  align-items: center;
  justify-content: center;

  @if $direction-column {
    flex-direction: column;
  }

  @if $justify {
    justify-content: center;
  }
}

@mixin is-tablet-portrait() {
  @media (min-width: $screen-tablet-portrait) {
    @content;
  }
}

@mixin is-desktop() {
  @media (min-width: $screen-desktop) {
    @content;
  }
}

@mixin is-desktop-large() {
  @media (min-width: $screen-desktop-large) and (min-height: 1000px) {
    @content;
  }
}

@mixin is-small-mobile() {
    @media (max-height: 550px) and (orientation: portrait) {
        @content;
    }
}

@mixin h1() {
  max-width: 160px;
  font-size: var(--title-font-size);
  font-weight: 700;
  line-height: 22px;
  font-family: $root-font-family;
  color: $light-text-color;
  text-align: center;
  text-transform: uppercase;

  @include is-tablet-portrait {
    font-size: var(--title-font-size-tablet);
    line-height: 33px;
    max-width: 450px;
  }

  @include is-desktop-large {
    font-size: var(--title-font-size-desktop-large);
    line-height: 37px;
  }
}

@mixin h3() {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  @include is-tablet-portrait {
    font-size: 15px;
    line-height: 15px;
  }
}

@mixin button-text() {
  color: $light-text-color;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

@mixin paragraph() {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $light-text-color;
  text-align: center;

  @include is-tablet-portrait {
    font-size: 18px;
    line-height: 28px;
  }

  @include is-desktop {
    font-size: 22px;
    line-height: 28px;
  }

  @include is-small-mobile{
    font-size: 14px;
    margin: 0;
  }
}
