@import "../../styles/utils";

.modalWrapper {
  @include flex-centered(true, true);
  z-index: 200;
  position: fixed;
  margin: 0;
  left: 0;
  top: 0;
  background-color: $modal-overlay;

  width: 100%;
  height: 100%;
  overflow: auto;
}

.flexWrapper {
  @include flex-centered(true, true);
  flex: 1;

  @include is-tablet-portrait {
    width: 45%;
  }
}

.innerWrapper {
  @include flex-centered(true, false);
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-width: 300px;
  max-width: 305px;
  min-height: 200px;
  background: $modal-background;
  border: none;
  box-shadow: 0 0 32px 0 rgba(255, 232, 176, 0.4);
  border-radius: var(--modals-border-radius);
  padding: var(--modals-padding);
  text-align: center;

  .modalTitle {
    @include h1();
    max-width: unset;
    font-size: 25px;
    position: relative;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .modalText {
    @include h3();
    margin-top: 1rem;
    color: $light-text-color;
    line-height: 16px;
    text-transform: uppercase;
  }

  @include is-tablet-portrait {
    max-width: 405px;
    min-height: 280px;
  }
}

.buttonsWrapper {
  @include flex-centered(false, true);
  align-items: flex-end;
  width: 87%;
  height: 85px;

  button {
    width: 115px;
    height: 55px;
    border-radius: 10px;

    @include is-tablet-portrait {
        width: 150px;
        height: 60px;

      .buttonOneText {
        margin-top: -10px;
      }
    }
  }

  button:last-child {
    text-decoration: underline;
  }
}

