@import "src/styles/utils";

.successContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $board-background-transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out;
  z-index: 50;

  svg {
    margin-right: 5px;
    animation: slideIn 1s ease-in-out;
  }

  p {
    font-size: 20px;
    color: white;
  }

  &.start {
    background-color: $board-background-transparent;
  }

  &.endGame {
    background-color: $background-color-transparent;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-40px); }
  to { transform: translateY(0); }
}
