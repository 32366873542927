@import "src/styles/utils";

.coundownContainer {

  .countdownText {
    @include flex-centered(false);
    h1 {
        font-size: 7rem;
        font-weight: 700;
        color: #fff;
        margin-bottom: 2rem;
    }
  }
}
