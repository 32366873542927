@import "src/styles/utils";

.bookmark {
  @include flex-centered(false, false);
  justify-content: space-evenly;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  color: $light-text-color;
  cursor: pointer;
  position: absolute;
  top: 14dvh;
  right: 100px;
  width: 70px;
  height: 25px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  z-index: 80;

  .bookmarkText {
    @include h3;
    margin-left: 0.2rem;
    line-height: 12px;
    font-weight: bold;
  }

  .bookmarkIcon {
    width: 22px;
    height: 22px;
  }

  &.bookmarkOpen {
    z-index: 90;
  }

  @include is-small-mobile {
    top: 85px;
  }
}

.container {
  @include flex-centered(true, true);
  justify-content: start;
  padding-top: 1em;
  position: absolute;
  top: 10dvh;
  right: 0;
  width: 270px;
  min-height: 400px;
  color: $light-text-color;
  background: $prizes-background;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  text-align: left;
  z-index: 100;

  .header {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 5px 0;
  }

  .paragraph {
    text-align: left;
    max-width: 240px;
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0 10px 0;
  }

  .randomizerContainer {
    width: 90%;
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  @include is-small-mobile {
    top: 11dvh;
  }
}

.breakLine {
  width: 85%;
  margin: 0;
  border-top: 1px solid $light-text-color;
}

@include is-tablet-portrait {
  .bookmark {
    top: calc($topbar-height + 20px);
    height: 35px;
    width: 70px;
  }

  .container {
    top: 14dvh;
  }
}

@include is-desktop {
  .bookmark {
    display: none;
  }

  .container {
    position: relative;
    transform: none !important;
    box-shadow: none;
    opacity: 1;
    width: 320px;
    min-height: 440px;
    background: $info-background;
    border-radius: 0 30px 30px 0;
  }

  @media screen and (max-height: 700px) {
    .container {
        top: 20dvh;
    }
  }
}

@include is-desktop-large {
  .container {
    top: 20dvh;
  }
}
